import { Service } from "../../config/service";

class TransferService extends Service {

  constructor() {
    super("transfer")
  }

  //add Transfer
  async addTransfer(body) {
    try {
      this.getResource().insert += '/join/setup'
      const {data} = await this.insert(body);
    }
    catch (e) {
      console.error(e)
      this.$toast.error(e.message)
    }
  }

  //delete Transfer
  async deleteTransfer(id) {
    try {
      
      this.getResource().delete += '/join/setup'
      this.setId(id);
      const {data} = await this.delete();
    }
    catch (e) {
      console.error(e)
      this.$toast.error(e.message)
    }
  }

}

export default TransferService
